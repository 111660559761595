@import "variables";

.color-primary {
  color: $colorPrimary;
}

.color-primary-8 {
  color: $colorPrimary;
  opacity: 0.8;
}

.color-primary-7 {
  color: $colorPrimary;
  opacity: 0.7;
}

.color-black {
  color: #000;
}

.fs-28 {
  font-size: 28px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.mt-60 {
  margin-top: 60px;
}