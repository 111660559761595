@import '../../variables.scss';

.onboarding-bg {
  background-image: url('../../../public/images/Home_Default.svg');
  background-color: #102065;
}

.hp-button {
  text-align: center;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    font-weight: 700;
    font-size: 18px;
    width: 303px;
    height: 56px;
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    box-shadow: 0px 12px 28px rgba(58, 255, 255, 0.2), inset 0px 0px 8px rgba(255, 255, 255, 0.5);
    border-radius: 35px;
    border: none;

    span {
      color: #fff;
      font-family: $fontPrimary;
    }
  }
}

.onboarding {
  background-image: url('../../../public/images/onboarding/onboarding-bg.svg');
  background-color: #102065;

  &-title {
    margin-top: -1.5rem;
  }

  &-title-2 {
    font-weight: 600;
    font-size: 14px;
    padding: 6px 16px;
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    border-radius: 33px;
    text-align: center;
  }

  &-logo {
    margin-top: 20%;
    position: relative;

    &-percentage {
      position: absolute;
      left: 50%;
      top: 27%;
      transform: translateX(-50%);
      color: #b6e9ff;
    }
  }
}

.onboarding-step {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    border-radius: 100px;
    width: 12px;
    height: 12px;
    background: #ffffff;
    opacity: 0.3;
    margin: 0 9px;
  }

  &-active {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    opacity: 0.2;
    border-radius: 100px;
    width: 24px;
    height: 24px;
  }

  &-active--fill {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    border-radius: 100px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 6px;
    left: 6px;
  }
}

.onboarding-gender {
  &-female,
  &-male {
    cursor: pointer;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(1.5px);
    width: 45%;
    height: 204px;
    margin-top: 60px;

    &-block {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      padding: 18px;
      margin: 40px auto 25px;
      background: rgba(255, 255, 255, 0.12);
    }
  }

  &--active {
    border: 2px solid #a05ed5;
    box-shadow: 0px 2px 28px rgba(218, 139, 243, 0.5);
    position: relative;

    &:after {
      background: url('../../../public/images/onboarding/Group 499.svg') no-repeat;
      content: '';
      top: -1px;
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
      transform: translate(-45%, 0px);
    }
  }
}

$sizes: 20px, 18px, 16px;

.onboarding-weight {
  .-hmru-picker-view {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 2px 28px rgba(59, 27, 255, 0.5);
    backdrop-filter: blur(1.5px);
    border-radius: 16px;

    &-column-item[aria-label=''] {
      .-hmru-picker-view-column-item-label {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);
      }
    }

    &-column-item[aria-label='active'] {
      .-hmru-picker-view-column-item-label {
        font-size: 35px;
      }
    }
  }

  .-hmru-picker-view-mask {
    &-top,
    &-bottom {
      background: unset;
    }

    &-middle {
      border: unset;
    }
  }

  .-hmru-picker-view-column-item-label {
    color: #fff;
  }
}

.onboarding-setting-switch {
  background: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 2px 28px rgba(59, 27, 255, 0.5);
  backdrop-filter: blur(1.5px);
  border-radius: 16px;
  padding: 22px 20px;

  .-hmru-switch-checkbox {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%) !important;
  }
}

.onboarding-info {
  &--value {
    background: linear-gradient(180deg, rgba(157, 185, 255, 0.5) 0%, rgba(52, 105, 241, 0.5) 100%);
    box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    width: fit-content !important;
    padding: 7px 15px;
    z-index: 99;
  }

  &-wave {
    background-image: url('../../../public/images/onboarding/bg-info.svg');
    background-position: -280px 0px;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.onboarding-droplets {
  position: relative;
  margin-top: 8rem;

  .drop-shape {
    position: absolute;
    padding: 0;
    width: 250px;
    height: 250px;
    border-radius: 0px 50% 70% 50%;
    overflow: hidden;
    left: calc(50% - 125px);
    border: 2px solid transparent;
    box-shadow: 0px 0px 20px rgba(59, 27, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drop {
    background: radial-gradient(#eabff4 0%, #2844d8 100%);
    transform: rotate(42deg) skewY(8deg) scale(var(--ratio));
    -webkit-transform: rotate(42deg) skewY(8deg) scale(var(--ratio));
    -moz-transform: rotate(42deg) skewY(8deg) scale(var(--ratio));
    &:before {
      content: '';
      border-radius: 100%;
      background-image: linear-gradient(to bottom, #eabff4 0%, #2844d8 100%);
      top: -120px;
      left: -10px;
      bottom: -10px;
      right: -10px;
      position: absolute;
      z-index: -1;
    }
    .drop-percentage {
      position: absolute;
      z-index: 99;
      font-size: 24px;
      color: white;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      font-family: $fontPrimary !important;
    }
  }

  .wave {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: rotate(-45deg);
    background-image: url('../../../public/images/wave-bubble.svg');
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .wave:before,
  .wave:after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    left: 50%;
    transform: translate(0%, 0%);
    top: var(--top-position);
  }

  .wave:before {
    border-radius: 45%;
    background: rgba(28, 43, 123, 1);
    animation: animate 5s linear infinite;
  }

  .wave:after {
    border-radius: 40%;
    background: rgba(28, 43, 123, 0.5);
    animation: animate 10s linear infinite;
  }

  @keyframes fillWater {
    0% {
      top: 120px;
    }
    100% {
      top: var(--top-position);
    }
  }

  @keyframes animate {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }

  .drop-outside {
    transform: scale(var(--ratio)) rotate(42deg) skewY(8deg);
    background: transparent;
    box-shadow: none;
    animation: drop-outside-animated 4s infinite;
    border: 1px solid #2844d8;
    opacity: 0.4;
  }

  .drop-outside-2 {
    transform: scale(var(--ratio)) rotate(42deg) skewY(8deg);
    background: transparent;
    box-shadow: none;
    animation: drop-outside-animated 4s infinite;
    border: 1px solid #2844d8;
    opacity: 0.4;
  }

  .drop:before {
    animation: ripple 2s linear infinite;
  }

  @keyframes wave {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
      opacity: 1;
    }

    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }
}

.skip-tour {
  position: absolute;
  z-index: 9999999;
  left: 20px;
  .skip-tour-btn {
    background: rgba(255, 255, 255, 0.1);
    border: 0;
    color: #fff;
    font-size: 14px;
    border-radius: 27px;
    position: relative;
    top: -5px;
  }
}

.tour-end {
  position: absolute;
  z-index: 9999999;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    font-weight: 700;
    font-size: 18px;
    width: 180px;
    height: 56px;
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    box-shadow: 0px 0px 24px #da8bf3, inset 0px 0px 8px rgba(255, 255, 255, 0.5);
    border-radius: 35px;
    border: none;

    span {
      color: #fff;
      font-family: $fontPrimary;
    }
  }
}

.mask-overlay {
  opacity: 0.7;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 2;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}
