.chose-drink-modal-container {
  .-hmru-modal {
    .-hmru-center-popup-mask {
      background: linear-gradient(156.38deg, rgba(234, 191, 244, 0.8) 0%, rgba(40, 68, 216, 0.8) 100%);
      backdrop-filter: blur(4px);
    }

    .-hmru-modal-body {
      background: #23399c;
      mix-blend-mode: normal;
      border-radius: 28px;
      position: relative;

      &:before {
        content: '';
        display: inline-block;

        position: absolute;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        border: 3px solid white;
        left: 50%;
        margin-left: -25px;
        top: -20px;
        background: red;
      }
    }
  }

  .-hmru-mask {
    background: linear-gradient(156.38deg, rgba(234, 191, 244, 0.8) 0%, rgba(40, 68, 216, 0.8) 100%) !important;
    border: 1.5px solid rgba(142, 159, 248, 0.1);
    backdrop-filter: blur(4px);
    z-index: 0;
  }

  .drink-success-modal {
    mix-blend-mode: normal;
    border-radius: 28px;
    width: 75%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px 40px;

    background: #23399c;
    min-height: 220px;

    animation: slideInBottom 0.5s forwards;
    .icon {
      display: block;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 10px;
    }
    .modal-close {
      bottom: -30%;
    }
  }

  .modal-close {
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 33px;
    position: absolute;
    bottom: -60%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    img {
      margin-top: 15px;
    }
  }

  .choose-drink-modal {
    mix-blend-mode: normal;
    border-radius: 28px;
    width: 80%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px 40px;

    background-image: url('../../../../public/images/drink/modal-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    min-height: 364px;

    animation: slideInBottom 0.5s forwards;

    .circle-drink-block {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circle-drink {
      background: linear-gradient(180deg, rgba(157, 185, 255, 0.6) 0%, rgba(52, 105, 241, 0.6) 100%);
      backdrop-filter: blur(10px);
      border-radius: 100px;
      width: 115px;
      height: 115px;
      position: relative;
      bottom: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      border: 2px solid #a05ed5;

    }

    .-hmru-input-element::placeholder {
      font-weight: 500;
      font-size: 18px;
    }

    &-content {
      padding: 0 24px;
      position: relative;
      top: -15px;

      .-hmru-list-body {
        box-shadow: 0px 2px 28px rgb(59 27 255 / 50%);
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(241, 157, 255, 0.5);
      }

      .-hmru-form-item.-hmru-form-item-horizontal.-hmru-list-item {
        background: rgba(255, 255, 255, 0.05);
      }

      .-hmru-input-element {
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        font-family: 'Maven Pro', sans-serif;
        opacity: 1;
      }

      .drink-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          height: 48px;
          border-radius: 35px;
          color: #fff;
          padding: 14px 0;
          font-weight: 700;
          font-size: 18px;
          line-height: 0px;
          width: 205px;
          font-family: 'Maven Pro', sans-serif;
          margin: 0 auto;
        }

        &-choose {
          background: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(2px);
          border: 1px solid rgba(255, 255, 255, 0.15);
        }

        &-continue {
          background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
          border: none;
        }

        &-cancel {
          width: 170px !important;
          margin-right: 10px !important;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.15);
        }
      }
    }
  }

  @keyframes slideInBottom {
    from {
      top: 30%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
}

.extra-input {
  border-left: solid 1px #a05ed4;
  padding-left: 12px;
  font-size: 17px;
  line-height: 22px;
}
