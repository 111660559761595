.onboarding-bg {
  background-image: url('../../../public/images/Home_Default.svg');
  background-color: #102065;
}

.choose-a-drink {
  color: #fff;

  &-today {
    height: 200px;
  }

  .-hmru-jumbo-tabs-tab-active .-hmru-jumbo-tabs-tab-description {
    background-color: unset;
  }

  .-hmru-jumbo-tabs-tab {
    margin: 0px;
    padding: 0px;
  }

  .-hmru-jumbo-tabs-header {
    border-bottom: unset;
  }

  .-hmru-jumbo-tabs-tab-description {
    background-color: unset;
  }

  .-hmru-scroll-mask {
    display: none;
  }

  .-hmru-jumbo-tabs-tab-list,
  .-hmru-jumbo-tabs-tab-wrapper,
  .-hmru-jumbo-tabs-tab-description {
    padding: 0;
  }

  .-hmru-jumbo-tabs-tab-description {
    margin-right: 8px;
  }

  .drink-item {
    background: linear-gradient(180deg, rgba(157, 185, 255, 0.2) 0%, rgba(52, 105, 241, 0.2) 100%);
    box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 15px 24px;
  }

  .drink-item-container:first-child {
    margin-left: 20px;
  }

  .list-drink {
    background-image: url('../../../public/images/drink/list-drink-bg.svg');
    background-repeat: no-repeat;
    height: calc(100vh - 200px);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: 100% 100%;

    &-container {
      padding: 40px 0px 60px;
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0px 24px;
    }

    &-item {
      margin-bottom: 25px;
      flex: 0 0 33.333333%;

      &-bubble {
        background: linear-gradient(180deg, rgba(157, 185, 255, 0.1) 0%, rgba(52, 105, 241, 0.1) 100%);
        box-shadow: inset 0px 0px 8px rgba(255, 255, 255, 0.2);
        border-radius: 100px;
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px auto;
      }
    }

    .drink-information {
      color: #da8bf3;
      font-size: 16px;
      margin-left: 5px;
    }
  }

  .-hmru-swiper {
    padding-bottom: 25px;
    padding-top: 20px;

    .-hmru-page-indicator-horizontal {
      .-hmru-page-indicator-dot-active,
      .-hmru-page-indicator-dot {
        border-radius: 100px;
        width: 10px;
        height: 10px;
        margin: 0 10px;
      }

      .-hmru-page-indicator-dot-active {
        background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%) !important;
      }

      .-hmru-page-indicator-dot {
        background: #fff;
      }
    }
  }
}
