.setting {
  .-hmru-switch-checkbox {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%) !important;
  }
}

.setting-block {
  background: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  padding: 0 16px;

  .block-item {
    border-bottom: 1px solid rgba(90, 110, 201, 0.3);
    padding: 16px 0;

    &:last-child {
      border-bottom: unset;
    }
  }
}

.-hmru-popup-body {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  background: #23399c;
  padding: 20px 24px 30px;
  box-sizing: border-box;
}

.-hmru-picker {
  &-header {
    border-bottom: unset;
    margin-bottom: 20px;
    align-items: start;

    &-button {
      padding: 0;
    }
  }

  &-popup {
    .-hmru-mask {
      background: linear-gradient(156.38deg, rgba(234, 191, 244, 0.8) 0%, rgba(40, 68, 216, 0.8) 100%) !important;
      backdrop-filter: blur(4px);
    }
  }

  &-view {
    border: 2px solid #a05ed5;
    background: #23399c;
    border-radius: 16px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 2px 28px rgba(59, 27, 255, 0.5);
    backdrop-filter: blur(1.5px);

    &-mask-top,
    &-mask-bottom {
      background: unset;
    }

    &-mask-middle {
      border: unset;
    }

    &-column-item[aria-label=''] {
      .-hmru-picker-view-column-item-label {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);
      }
    }

    &-column-item[aria-label='active'] {
      .-hmru-picker-view-column-item-label {
        font-size: 30px;
        color: #fff;
      }
    }
  }
}

.select-gender {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 15px;
  gap: 8px;
  color: #fff;
  .active {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    border-radius: 4px;
    padding: 2px 10px;
  }
}
