.history {
  &-item {
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);

    &--active {
      background: linear-gradient(180deg, rgba(157, 185, 255, 0.1) 0%, rgba(52, 105, 241, 0.1) 100%);
      box-shadow: 0px 0px 20px rgba(218, 139, 243, 0.5), inset 0px 0px 8px rgba(255, 255, 255, 0.2);
      border: 2px solid #a05ed5;
      border-radius: 20px;
    }
  }

  &-progress {
    padding: 16px;

    .-hmru-progress-circle-info {
      color: #fff;
    }
  }
}
