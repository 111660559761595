@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'classes';

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $fontSizePrimary;
  color: $colorPrimary;
  font-family: $fontPrimary !important;
  font-weight: 500;
  margin: auto auto;
}

.-hmru-dialog-title {
  font-family: 'Roboto', sans-serif;
}

.-hmru-dialog-body {
  border-radius: 14px !important;

  .-hmru-dialog-content {
    padding: 0 15px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .-hmru-dialog-footer {
    button {
      font-size: 14px !important;
    }
  }
}

section.section-bg {
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.section-container {
  padding: 20px;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify__close-button {
  align-self: center !important;
}

.Toastify__toast--success {
  background-color: #E5F7ED !important;
  color: #101434 !important;
}

.Toastify__toast {
  border-radius: 8px !important;
}