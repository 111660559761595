.onboarding-bg {
  background-image: url('../../../../public/images/Home_Default.svg');
  background-color: #102065;
}

.hp-button {
  text-align: center;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    font-weight: 700;
    font-size: 18px;
    //padding: 15px 118px;
    width: 303px;
    height: 56px;
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    box-shadow: 0px 12px 28px rgba(58, 255, 255, 0.2), inset 0px 0px 8px rgba(255, 255, 255, 0.5);
    border-radius: 35px;
    border: none;

    span {
      color: #fff;
      font-family: 'Maven Pro', sans-serif;
    }
  }
}

.onboarding {
  background-image: url('../../../../public/images/onboarding/onboarding-bg.svg');
  background-color: #102065;
  &-title {
    margin-top: -1.5rem;
  }

  &-title-2 {
    font-weight: 600;
    font-size: 14px;
    padding: 6px 16px;
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    border-radius: 33px;
    text-align: center;
  }

  &-logo {
    margin-top: 20%;
    position: relative;

    &-percentage {
      position: absolute;
      left: 50%;
      top: 27%;
      transform: translateX(-50%);
      color: #b6e9ff;
    }
  }
}

.onboarding-step {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    border-radius: 100px;
    width: 12px;
    height: 12px;
    background: #ffffff;
    opacity: 0.3;
    margin: 0 9px;
  }

  &-active {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    opacity: 0.2;
    border-radius: 100px;
    width: 24px;
    height: 24px;
  }

  &-active--fill {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%);
    border-radius: 100px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 6px;
    left: 6px;
  }
}

// Choose Gender
.onboarding-gender {
  &-female,
  &-male {
    cursor: pointer;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(1.5px);
    width: 45%;
    height: 204px;
    margin-top: 60px;

    &-block {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      padding: 18px;
      margin: 40px auto 25px;
      background: rgba(255, 255, 255, 0.12);
    }
  }

  &--active {
    border: 2px solid #a05ed5;
    box-shadow: 0px 2px 28px rgba(218, 139, 243, 0.5);
    position: relative;

    &:after {
      background: url('../../../../public/images/onboarding/Group 499.svg') no-repeat;
      content: '';
      top: -1px;
      position: absolute;
      height: 100%;
      left: 50%;
      width: 72px;
      transform: translateX(-50%);
    }
  }
}

$sizes: 20px, 18px, 16px;

.onboarding-weight {
  .-hmru-picker-view {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 2px 28px rgba(59, 27, 255, 0.5);
    backdrop-filter: blur(1.5px);
    border-radius: 16px;

    &-column-item[aria-label=''] {
      .-hmru-picker-view-column-item-label {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);
      }

      //@each $size in $sizes {
      //  .-hmru-picker-view-column-item-label {
      //    font-size: $size;
      //  }
      //}
      //background: #000;
    }

    &-column-item[aria-label='active'] {
      .-hmru-picker-view-column-item-label {
        font-size: 35px;
      }
    }
  }

  .-hmru-picker-view-mask {
    &-top,
    &-bottom {
      background: unset;
      //mask: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%);
    }

    &-middle {
      border: unset;
    }
  }

  .-hmru-picker-view-column-item-label {
    color: #fff;
    //font-size: 35px;
  }
}

.onboarding-setting-switch {
  background: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 2px 28px rgba(59, 27, 255, 0.5);
  backdrop-filter: blur(1.5px);
  border-radius: 16px;
  padding: 22px 20px;

  .-hmru-switch-checkbox {
    background: linear-gradient(180deg, #f19dff 0%, #a05ed5 100%) !important;
  }
}

